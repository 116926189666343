import {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5Entity} from "../../E5Entity";

//E5
export interface E5SysCrashDetail {
	procid: number|null;
	procname: string;
	softwarev: string;
	coreDumpFileName: string;
	coreDumpSize: number;
	time: Moment;
}

//E5
export class E5EntHIndSysCrash extends E5Entity {
	imei: string = "";
	crashdetail: E5SysCrashDetail[]|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let idx: number, det: any;
		this.crashdetail = null;
		if (Array.isArray(json.crash_details)) {
			this.crashdetail = [];
			for (idx = 0; idx < json.crash_details.length; idx++) {
				det = json.crash_details[idx];
				this.crashdetail.push({
					procid: E5Entity.ParseIntNull(det.process_id), 
					procname: E5Entity.AssignString(det.process_name),
					softwarev: E5Entity.AssignString(det.software_version),
					coreDumpFileName: E5Entity.AssignString(det.core_dump_file_name),
					coreDumpSize: E5Entity.ParseInt(det.core_dump_size),
					time: E5Text.Tm2MomentTz(E5Entity.ParseInt(det.time), E5Time.h_tzoffset)
				});
			}
		}
	}

	//E5
	CopyToJson(): any {
		let crash_details: any = null, idx: number;
		if (Array.isArray(this.crashdetail)) {
			crash_details = [];
			for (idx = 0; idx < this.crashdetail.length; idx++) crash_details.push({
				process_id: this.crashdetail[idx].procid, process_name: this.crashdetail[idx].procname,
				software_version: this.crashdetail[idx].softwarev, time: this.crashdetail[idx].time.unix()
			});
		}

		return {node_imei: this.imei, crash_details};
	}
}
