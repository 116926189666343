import React, { createRef } from "react";
import { Moment } from "moment";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5StoreAdminUserGet, E5StoreAdminUsers } from "../../../store/E5StoreAdmin";
import { E5AdminUsersRolesLegend } from "./E5AdminUsersRolesLegend";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import { E5AdminUsersPopup } from "./E5AdminUsersPopup";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreACL } from "../../../store/E5StoreACL";
import { E5EntUser } from "../../../entity/E5EntUser";
//
import "./E5AdminUsersList.css";
import { ReactComponent as Users } from '../../../assets/users.svg'
import UpdateSelectedUsersModal from './UpdateSelectedUsersModal.component';
import Table from '../../../global/component/E5Table'
import TextFieldStyled from '../../../global/component/TextFieldStyled'
import RemoveSelectedUsersModal from "./RemoveSelectedUsersModal.component";
import { styled } from "@mui/material/styles";
import DownloadUsersModal from './DownloadUsersModal';
//E5
export enum E5AdminUsersAction { add = "add", delete = "delete", update = "update", deleteMany = "deleteMany", download = 'download' }

//E5
interface E5AdminUsersListState {
	filtereddata: E5EntUser[];
	activefilter: any;
	filterinput: any;
	sortfield: string;
	sortdirection: string;
	rowheights: number[];
	dialogopen: boolean;
	deleteopen: boolean;
	curselecteduser: E5EntUser;
	curediteduser: E5EntUser;
	curediteduserchanged: boolean;
	showFilter: boolean;
	checkedRow: number;
	selectedUsersIds: string[];
	selectedUsersEmails: string[],
	isUpdateUsersModalOpen: boolean;
	isRemoveUsersModalOpen: boolean;
	isDownloadUsersModalOpened: boolean;
	rowSelectionModel: string[];
	filterUserInput: string | null;
}

//E5
export type E5AdminUserSaveReceive = (success: boolean) => void;

//E5
export type E5AdminUserSaveSend = (action: E5AdminUsersAction, user: E5EntUser, username: string,
	callback: E5AdminUserSaveReceive) => void;

//E5
interface E5AdminUsersListProps {
	langinfo: E5StoreLangInfo;
	userinfo: E5StoreAdminUsers;
	usergetinfo: E5StoreAdminUserGet;
	savefunc: E5AdminUserSaveSend;
}



//E5
export const E5AdminUsersList = observer(class E5AdminUsersList extends React.PureComponent
	<E5AdminUsersListProps, E5AdminUsersListState> {

	// ---------------- MEMBERS ----------------

	//E5
	filtertimer?: NodeJS.Timeout;
	stop_change_users?: IReactionDisposer;
	listColumnsToFilter: string[] = ['username', 'lastname', 'firstname'];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminUsersListProps, state: E5AdminUsersListState) {
		super(props, state);
		this.textRef = createRef();
		this.state = {
			activefilter: {}, filterinput: {}, sortfield: "lastname", sortdirection: "up", filtereddata: [],
			rowheights: [], dialogopen: false, deleteopen: false, curselecteduser: new E5EntUser(),
			curediteduser: new E5EntUser(), curediteduserchanged: false, showFilter: false, isUpdateUsersModalOpen: false,
			isRemoveUsersModalOpen: false, rowSelectionModel: [], filterUserInput: null, isDownloadUsersModalOpened: false,
		};
	}

	//E5
	componentDidMount(): void {
		this.BuildFiltered();
		this.stop_change_users = reaction(() => this.props.userinfo.users,
			() => this.BuildFiltered());
	}

	//E5
	componentWillUnmount(): void {
		if (this.filtertimer !== undefined) clearTimeout(this.filtertimer);
		this.stop_change_users?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		let curuserisnew: boolean = this.state.curselecteduser.username === "",
			loading: boolean = this.props.userinfo.status.loading || this.props.usergetinfo.status.loading;
		let roleslistfilter: string[] =
			["ROLE_L1", "ROLE_L2", "ROLE_L3", "ROLE_RDMANAGER", "ROLE_MANAGER", "ROLE_ADMIN", "ROLE_ANALYST"];
		let cblist: JSX.Element[] = [], acljsx: JSX.Element[] = [];
		for (let role of roleslistfilter) cblist.push(<BP.Tooltip
			key={role} boundary="window" content={E5UtilI18n._("admin-users-role-" + role + "_long")}>
			<BP.Checkbox key={role} inline label={E5UtilI18n._("admin-users-role-" + role)}
				checked={this.state.filterinput?.["roles"]?.[role] ?? false} value={role}
				onChange={(event: any) => { this.ChangeFilterCheckbox("roles", role, event); }} />
		</BP.Tooltip>);
		let roles: JSX.Element[] = [];
		for (let role of roleslistfilter) roles.unshift(<BP.Tooltip
			key={role} boundary="window" content={E5UtilI18n._("admin-users-role-" + role + "_long")}>
			<BP.Checkbox key={role} inline label={E5UtilI18n._("admin-users-role-" + role)}
				checked={this.state.filterinput?.["roles"]?.[role] ?? false} value={role}
				className="asRadiobutton"
				onChange={(event: any) => { this.ChangeFilterCheckbox("roles", role, event); }} />
		</BP.Tooltip>);

		for (let { name } of E5StoreACL.Ins().aclinfo.groups)
			acljsx.push(<BP.Checkbox key={name} label={name} value={name} onChange={
				(event: any) => { this.ChangeFilterCheckbox("aclgroups", name, event); }}
				checked={this.state.filterinput?.["aclgroups"]?.[name] ?? false} />);

		return <div className="e5admin-users-list e5columnfull e5column-20">
			<div className="e5compo e5admin-users-header e5column-10">
				<div className="e5line-5 e5admin-users-titleline">
					<div className="e5compotitle e5admin-users-filterbloc">
						{E5UtilI18n._("admin-users-filters")}</div>
					{/* {!loading && this.CheckHasFilter() &&
						<BP.Button className={'delete-filters-button'} icon="cross" text={E5UtilI18n._("delfilters")} onClick={this.DelFilters} />} */}
					{loading && <BP.Spinner className="e5spinwait" size={25} />}
				</div>
				<div className="e5line-5 e5admin-users-filterline e5admin-users-titleline">
					<TextFieldStyled className='e5admin-users-filterbloc-search'
						inputRef={this.textRef}
						id="outlined-basic" label="Search" variant="outlined"
						onChange={(event: any) => { this.ChangeFilterInput(event) }} />



					{!loading && this.CheckHasFilter() &&
						<BP.Button className={'delete-filters-button'} style={{ marginLeft: '15px' }}
							icon="cross" text={E5UtilI18n._("delfilters")}
							onClick={(data) => { this.textRef.current.value = ''; this.DelFilters(data) }} />}
					<div className="e5admin-users-filterbloc">
						<Users className='filter-icon' onClick={this.onOpenDownloadUsersModal} />
					</div>
					{this.state.rowSelectionModel.length > 0 && <div className="e5admin-users-filterbloc" onClick={() => { this.setState({ isUpdateUsersModalOpen: true }) }}>
						<div className='updateButton'>Update the selected users</div>
					</div>}
					{this.state.rowSelectionModel.length > 0 && <div onClick={() => { this.setState({ isRemoveUsersModalOpen: true }) }} className="e5admin-users-filterbloc">
						<div className='deleteButton'>Delete the selected users</div>
					</div>}
					<div className="e5linefull" />
					<E5AdminUsersRolesLegend langinfo={E5Store.Ins().langinfo}
						text={E5UtilI18n._("admin-users-rolesbut")} />
					<BP.Button icon="add" text={E5UtilI18n._("admin-users-popup-add")} onClick={this.AddUser} />
				</div>
			</div>
			<Table
				pageSize={25}
				checkboxSelection={true}
				rowSelectionModel={this.state.rowSelectionModel}
				className=''
				onRowDoubleClick={(data) => {
					const user = this.state.filtereddata.filter((user) => user.email === data.row.id);
					this.OpenUser(user[0])
				}}
				onCellClick={(cell) => {
					if (cell.field === "__check__") {
						const currentCheckedRows = [...this.state.rowSelectionModel];
						if (!currentCheckedRows.includes(cell.id)) {
							currentCheckedRows.push(cell.id)
						} else {
							var index = currentCheckedRows.indexOf(cell.id);    // <-- Not supported in <IE9
							if (index !== -1) {
								currentCheckedRows.splice(index, 1);
							}
						}
						this.setState({ checkedRow: cell.id, rowSelectionModel: currentCheckedRows, selectedUsersIds: currentCheckedRows, selectedUsersEmails: currentCheckedRows })
					}
				}}
				columns={[
					{
						field: 'lastname',
						headerName: E5UtilI18n._('admin-users-lastname'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						renderCell: (params) => {
							return params.row.lastname;
						},
					},
					{
						field: 'firstname',
						headerName: E5UtilI18n._('admin-users-firstname'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						renderCell: (params) => {
							return params.row.firstname;
						},
					},
					{
						field: 'username',
						headerName: E5UtilI18n._('admin-users-username'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						renderCell: (params) => {
							return params.row.username;
						},
					},
					{
						field: 'rolea',
						headerName: E5UtilI18n._('admin-users-roles'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						minWidth: 250,
						renderCell: (params) => {
							const arrayOfRoles = params.row.roles.split(', ');
							return <div>{arrayOfRoles.map((item) => {
								if (item === 'Analyst') {
									return <span key={item} style={{ backgroundColor: '#ede6f5', color: '#9031be' }} className="role-in-table">{item}</span>
								}
								if (item === 'Manager') {
									return <span key={item} style={{ backgroundColor: '#ebeafa', color: '#5348d2' }} className="role-in-table">{item}</span>
								}
								if (item === 'Sup. L1') {
									return <span key={item} style={{ backgroundColor: '#eaf1e2', color: '#6aa609' }} className="role-in-table">{item}</span>
								}
								if (item === 'Sup. L2') {
									return <span key={item} style={{ backgroundColor: '#fff9ed', color: '#ffb94e' }} className="role-in-table">{item}</span>
								}
								if (item === 'Sup. L3') {
									return <span key={item} style={{ backgroundColor: '#fae9e4', color: '#fd5d18' }} className="role-in-table">{item}</span>
								}
								if (item === 'Administrator') {
									return <span key={item} style={{ backgroundColor: '#fae4e5', color: '#cb0909' }} className="role-in-table">{item}</span>
								}
								if (item === 'RDM') {
									return <span key={item} style={{ backgroundColor: '#dce3ef', color: '#3c87f8' }} className="role-in-table">{item}</span>
								}
							})}</div>
						},
					},
					{
						field: 'accountexpirationdate',
						headerName: E5UtilI18n._('admin-users-accountexpirationdate'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						renderCell: (params) => {
							return params.row.accountexpirationdate || 'none';
						},
					},
					{
						field: 'lastsuccessfullogin',
						headerName: E5UtilI18n._('admin-users-lastsuccessfullogin'),
						editable: true,
						headerAlign: 'center',
						align: 'left',
						renderCell: (params) => {
							return params.row.lastsuccessfullogin;
						},
					},
					{
						field: 'status',
						headerName: E5UtilI18n._('admin-users-status'),
						editable: true,
						headerAlign: 'center',
						align: 'center',
						minWidth: 50,
						width: 150,
						renderCell: (params) => {
							return params.row.accountnonlocked === true ? <img src='./img/v3/connectivity/account-unlocked.svg' alt="Account unlocked" /> : <img src='./img/v3/connectivity/account-locked.svg' alt="Accountlocked" />
						}
					}]}
				rows={
					[...this.state.filtereddata.map((row, index) => {
						return (
							{
								...row,
								id: row.email,
								lastsuccessfullogin: row.lastsuccessfullogin?.format(E5UtilI18n._("dateformat") + " HH:mm"),
								accountexpirationdate: row.accountexpirationdate?.format(E5UtilI18n._("dateformat")),
								roles: row.roles.map(role => E5UtilI18n._("admin-users-role-" + role)).join(', '),
								aclgroups: row.aclgroups.map((acl: E5EntAclGroup) => acl.name).join(", "),
								status: row.accountnonexpired,
							}
						)
					}

					)]
				}
			/>

			{this.state.isUpdateUsersModalOpen && <UpdateSelectedUsersModal onClose={this.onCloseUpdateUsersModal} usersEmails={this.state.selectedUsersEmails} users={this.state.filtereddata} saveFunction={this.props.savefunc} updateAction={E5AdminUsersAction.update} updateRows={() => this.setState({ rowSelectionModel: [] })} />}
			{this.state.isRemoveUsersModalOpen && <RemoveSelectedUsersModal onClose={this.onCloseRemoveUsersModal} usersEmails={this.state.selectedUsersEmails} users={this.state.filtereddata} saveFunction={this.props.savefunc} deleteAction={E5AdminUsersAction.deleteMany} updateRows={() => this.setState({ rowSelectionModel: [] })} />}
			{this.state.isDownloadUsersModalOpened && <DownloadUsersModal onClose={this.onCloseDownloadUsersModal} users={this.state.filtereddata} downloadUsers={this.props.downloadUsers} />}

			<BP.Dialog isOpen={this.state.dialogopen} canOutsideClickClose={false} canEscapeKeyClose={false}
				title={E5UtilI18n._(curuserisnew ? "admin-users-popup-add" : "admin-users-popup-update")}
				onClose={this.CloseUser}>
				<div className={BP.Classes.DIALOG_BODY}>
					<E5AdminUsersPopup langinfo={this.props.langinfo} user={this.state.curselecteduser}
						updatefunc={this.UserIsUpdated} />
				</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS}>
						{!this.props.usergetinfo.status.loading ? <>
							{/* {!curuserisnew &&
								<div></div>} */}
							{this.state.curediteduserchanged &&
								<BP.Button text={curuserisnew ? 'Add this new user' : E5UtilI18n._(curuserisnew ? "add" : "save")}
									className="admin-add-button"
									intent={BP.Intent.PRIMARY} onClick={curuserisnew ? this.HandleSave : this.HandleUpdate} />}
							{!this.state.curediteduserchanged && <div></div>}
							<BP.Button text={E5UtilI18n._("cancel")} onClick={this.CloseUser} />
						</> : <div style={{ height: "30px" }} />}
					</div>
				</div>
			</BP.Dialog>
		</div>;
	}

	//E5
	RenderColumn = (field: string): JSX.Element =>
		<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, field)}
			columnHeaderCellRenderer={() => this.HeadRenderer(field)} />;

	//E5
	CellRenderer = (rowidx: number, field: string): JSX.Element => {
		let idx: number, cell: JSX.Element = <div />;
		if (rowidx < this.state.filtereddata.length) {
			// double check this.state.filtereddata.length because this code may be called outside of boundaries
			let val: string = "", datavalue: any = (this.state.filtereddata[rowidx] as any)[field],
				curselecteduser: E5EntUser = this.state.filtereddata[rowidx];
			if (field === "roles") {
				for (idx = 0; idx < datavalue.length; idx++) {
					val += E5UtilI18n._("admin-users-role-" + datavalue[idx]);
					if (idx < datavalue.length - 1) val += ", ";
				}
			} else if (field === "accountexpirationdate") {
				let mm: Moment | undefined = datavalue as Moment;
				if (mm === undefined) val = "";
				else val = mm.format(E5UtilI18n._("dateformat"));
			} else if (field === "lastsuccessfullogin") {
				let mm: Moment | undefined = datavalue as Moment;
				if (mm === undefined) val = "";
				else val = mm.format(E5UtilI18n._("dateformat") + " HH:mm");
			} else if (field === "aclgroups") {
				val = datavalue.map((acl: E5EntAclGroup) => acl.name).join(", ");
			} else {
				val = datavalue;
			}
			cell = <BPT.Cell>
				<div onDoubleClick={() => this.OpenUser(curselecteduser)}>{val}</div>
			</BPT.Cell>;
		}
		return cell;
	};

	//E5
	HeadRenderer = (field: string): JSX.Element => {
		let cell: JSX.Element, classname: BP.IconName = "double-caret-vertical" as BP.IconName,
			intent: BP.Intent = BP.Intent.NONE;
		if (this.state.sortfield === field) {
			intent = BP.Intent.DANGER;
			if (this.state.sortdirection === "up") classname = "caret-up";
			else classname = "caret-down";
		}
		let hassort: boolean = field !== "roles" && field !== "accountexpirationdate" && field !== "lastsuccessfullogin";
		cell = <div className="tbhead">
			<div className="tbheadname">{E5UtilI18n._("admin-users-" + field)}</div>
			{hassort && <BP.Button icon={classname} minimal small intent={intent} className="tbheadsort"
				onClick={() => { this.ChangeSort(field); }} />}
		</div>;
		return cell;
	};

	// ---------------- EVENTS ----------------
	openUpdateUserModal = (checkedRow: number, filtereddata: E5EntUser[]) => {
		this.OpenUser(filtereddata[checkedRow])
	}

	//E5
	ChangeFilterInput = (event: any): void => {
		let value: string | undefined = event.currentTarget.value;

		if (value === "") value = undefined;

		let filterinput: any = this.CloneFilterInput();

		this.listColumnsToFilter.forEach((field) => {
			filterinput[field] = value;
		});

		this.setState({ filterinput }, () => {
			if (this.filtertimer !== undefined) clearTimeout(this.filtertimer);
			this.filtertimer = setTimeout(() => { this.ChangeFilterInput2(value); }, 600);
		});
	};

	//E5
	ChangeFilterInput2 = (value: string | undefined): void => {
		let activefilter: any = this.CloneActiveFilter();
		if (value !== undefined) value = this.NormalizeSyntax(value);

		this.listColumnsToFilter.forEach((field) => {
			activefilter[field] = value;
		});
		
		this.BuildFiltered(activefilter, undefined, undefined);
	};
	onCloseUpdateUsersModal = () => {
		this.setState({ isUpdateUsersModalOpen: false })
	};

	onCloseRemoveUsersModal = () => {
		this.setState({ isRemoveUsersModalOpen: false, hightTable: false, hightTable: true })
	}
	onOpenDownloadUsersModal = () => {
		this.setState({ isDownloadUsersModalOpened: true })
	};
	onCloseDownloadUsersModal = () => {
		this.setState({ isDownloadUsersModalOpened: false })
	};
	//E5
	ChangeFilterCheckbox = (field: string, checkboxitem: string, event: any): void => {
		let checked = event.currentTarget.checked, filterinput: any = this.CloneFilterInput();
		if (filterinput[field] === undefined) filterinput[field] = {};
		filterinput[field][checkboxitem] = checked;

		let allunchecked: boolean = true;
		for (let key in filterinput[field])
			if (filterinput[field].hasOwnProperty(key) && filterinput[field][key] === true) {
				allunchecked = false;
				break;
			}
		if (allunchecked) filterinput[field] = undefined;

		this.setState({ filterinput }, () => {
			let newactivefilter: any = this.CloneActiveFilter();
			if (newactivefilter[field] === undefined) newactivefilter[field] = {};
			newactivefilter[field][checkboxitem] = checked;
			if (allunchecked) newactivefilter[field] = undefined;
			this.BuildFiltered(newactivefilter, undefined, undefined);
		});
	};

	//E5
	ChangeSort = (field: string) => {
		let direction: string = "up";
		if (this.state.sortfield === field && this.state.sortdirection === "up") direction = "down";
		this.BuildFiltered(undefined, field, direction);
	};

	//E5
	OpenUser = (curselecteduser: E5EntUser): void => this.setState(
		{ dialogopen: true, curediteduser: curselecteduser, curselecteduser, curediteduserchanged: false });

	//E5
	AddUser = (): void => {

		this.setState({
			dialogopen: true, curediteduser: new E5EntUser(), curselecteduser: new E5EntUser(), curediteduserchanged: false
		});
	}

	//E5
	HandleDelete = (): void => this.setState({ deleteopen: true });

	//E5
	HandleDelete2 = (): void => {
		this.CloseDelete();
		this.props.savefunc(E5AdminUsersAction.delete, this.state.curselecteduser, this.state.curselecteduser.username,
			(success: boolean) => {
				if (success) this.CloseUser();
			});
	};

	//E5
	HandleSave = (): void =>
		this.props.savefunc(E5AdminUsersAction.add, this.state.curediteduser, this.state.curselecteduser.username,
			(success: boolean) => {
				if (success) this.CloseUser();
			});
	HandleUpdate = (): void =>
		this.props.savefunc(E5AdminUsersAction.update, this.state.curediteduser, this.state.curselecteduser.username,
			(success: boolean) => {
				if (success) this.CloseUser();
			});
	//E5
	CloseUser = (): void => this.setState({ dialogopen: false, deleteopen: false });

	//E5
	CloseDelete = (): void => this.setState({ deleteopen: false });

	//E5
	UserIsUpdated = (user: E5EntUser): void =>
		this.setState({ curediteduser: user, curediteduserchanged: true, deleteopen: false });

	//E5
	DelFilters = (): any => this.setState({ filterinput: {} }, () =>
		this.BuildFiltered({}, undefined, undefined));

	// ---------------- UTILS ----------------

	//E5
	BuildFiltered = (activefilter?: any, sortfield?: string, sortdirection?: string) => {
		if (activefilter === undefined) activefilter = this.state.activefilter;
		if (sortfield === undefined) sortfield = this.state.sortfield;
		if (sortdirection === undefined) sortdirection = this.state.sortdirection;

		let filtereddata: any[] = [], idx: number;
		for (let user of this.props.userinfo.users) {
			let okname: boolean = activefilter["username"] === undefined ||
				this.NormalizeSyntax(user.username).indexOf(activefilter["username"]) >= 0;

			let oklname: boolean = activefilter["lastname"] === undefined ||
					this.NormalizeSyntax(user.lastname).indexOf(activefilter["lastname"]) >= 0;

			let okfname: boolean = activefilter["firstname"] === undefined ||
					this.NormalizeSyntax(user.firstname).indexOf(activefilter["firstname"]) >= 0;

			if (okname || oklname || okfname) {
				filtereddata.push(user);
			}
		}

		let heights: number[] = [];
		for (idx = 0; idx < filtereddata.length; idx++) heights.push(20);

		this.setState({ activefilter, sortfield, sortdirection }, () => {
			filtereddata.sort(this.Compare);
			console.log('filtered', filtereddata)
			this.setState({ filtereddata: filtereddata, rowheights: heights });
		});
	};

	//E5
	CheckHasFilter = (): boolean => {
		let has: boolean = false;
		for (let key in this.state.filterinput) if (this.state.filterinput.hasOwnProperty(key)) {
			let value = this.state.filterinput[key];
			if (value !== undefined) {
				has = true;
				break;
			}
		}
		return has;
	};

	//E5
	Compare = (va: E5EntUser, vb: E5EntUser): number => {
		let ret: number = 0;
		if (this.state.sortfield === "username") {
			if (this.state.sortdirection === "up") ret = va.username.localeCompare(vb.username);
			else ret = vb.username.localeCompare(va.username);
		}
		if (this.state.sortfield === "lastname") {
			if (this.state.sortdirection === "up") ret = va.lastname.localeCompare(vb.lastname);
			else ret = vb.lastname.localeCompare(va.lastname);
		}
		if (this.state.sortfield === "firstname") {
			if (this.state.sortdirection === "up") ret = va.firstname.localeCompare(vb.firstname);
			else ret = vb.firstname.localeCompare(va.firstname);
		}
		if (this.state.sortfield === "company") {
			if (this.state.sortdirection === "up") ret = va.company.localeCompare(vb.company);
			else ret = vb.company.localeCompare(va.company);
		}
		if (this.state.sortfield === "email") {
			if (this.state.sortdirection === "up") ret = va.email.localeCompare(vb.email);
			else ret = vb.email.localeCompare(va.email);
		}
		if (this.state.sortfield === "jobtitle") {
			if (this.state.sortdirection === "up") ret = va.jobtitle.localeCompare(vb.jobtitle);
			else ret = vb.jobtitle.localeCompare(va.jobtitle);
		}
		if (this.state.sortfield === "aclgroups") {
			let x = va.aclgroups.map(a => `${ a.name }`).join(",")
			let y = vb.aclgroups.map(b => `${ b.name }`).join(",")
			if (this.state.sortdirection === "up") ret = x.localeCompare(y);
			else ret = y.localeCompare(x);
		}
		return ret;
	};

	//E5
	NormalizeSyntax = (val: string | undefined): any => {
		// normalize = sépare les caractères spéciaux (é => e')
		// replace = supprimer les caractères spéciaux
		if (val !== undefined)
			val = val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		return val;
	};

	//E5
	CloneActiveFilter = (): any => Object.assign({}, this.state.activefilter);

	//E5
	CloneFilterInput = (): any => Object.assign({}, this.state.filterinput);

});
